"use strict";

var body = $("body");
var nav = $(".main-nav");

function clickListener(event){
	var target = $(event.target);

	if (target.hasClass("toggle-menu")) {
		return;
	}

	if (target.closest(".main-nav").length > 0 && !target.is("a")) {
		return;
	}

	nav.removeClass("open");
	body.off("click", clickListener);
}

$(".toggle-menu").click(function(){
	nav.toggleClass("open");
	if (nav.hasClass("open")) {
		body.on("click", clickListener);
	}
	else {
		body.off("click", clickListener);
	}
});
